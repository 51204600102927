import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { PageHeader } from "../components/header"

const SectionHeading = ({ children }) => {
  return (
    <h2 className="font-bold mb-2 text-3xl text-secondary-500">{children}</h2>
  )
}

const Item = props => {
  const {
    title,
    subtitle,
    children,
    date,
    technologies = [],
    website,
    repository,
  } = props

  return (
    <>
      <div className="flex flex-wrap justify-between mb-3">
        <div>
          <span className="font-bold">{subtitle}</span> |{" "}
          <span className="italic font-light">{title}</span>
          {technologies.length > 0 && (
            <p className="text-gray-500 text-sm">
              {technologies.map(tech => tech.name).join(", ")}
            </p>
          )}
        </div>
        {date && (date.from || date.to) ? (
          <div>
            {date.from && <span>{date.from} - </span>}
            {date.to && <span>{date.to}</span>}
          </div>
        ) : (
          <div className="space-x-3 divide-x-2">
            {website && (
              <a
                className="text-primary-500 hover:text-primary-700"
                href={website}
                target="_blank"
                rel="noopener noreferrer"
              >
                website
              </a>
            )}
            {repository && (
              <a
                className="text-primary-500 hover:text-primary-700 pl-2"
                href={repository}
                target="_blank"
                rel="noopener noreferrer"
              >
                github
              </a>
            )}
          </div>
        )}
      </div>
      {Array.isArray(children) ? (
        <div className="resume-content">
          <ul>
            {children.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          className="resume-content"
          dangerouslySetInnerHTML={{ __html: children }}
        ></div>
      )}
    </>
  )
}

const Skills = ({ skills }) => {
  Object.entries(skills).map(([skill, values], i) => (
    <li key={i} className="text-gray-600 flex mb-3 pt-4">
      <div className="mr-2 text-gray-500 font-bold w-64">{skill}</div>
      <p className="flex-1">
        {values.map(({ technology }) => technology.name).join(", ")}
      </p>
    </li>
  ))
}
const ResumePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const {
    name,
    github,
    linkedin,
    email,
    resume,
    skills,
    projects,
    experience,
    education,
  } = frontmatter

  return (
    <Layout>
      <SEO
        title="Resume"
        description="Randall Lough's resume"
        path="/resume"
        keywords={["resume", "experience", "projects"]}
      />
      <section className="mb-16">
        <div className="max-w-4xl px-5 mx-auto">
          <div className="flex flex-wrap">
            <h1 className="font-bold font-serif leading-tight text-4xl md:text-6xl text-primary-500 pb-3">
              {name}
            </h1>
            <div className="ml-auto self-center">
              <a
                href={resume.publicURL}
                download
                className="border border-secondary-500 hover:border-transparent bg-transparent hover:bg-secondary-400 rounded px-4 py-2 text-secondary-500 hover:text-white inline-block transition transform duration-200 hover:-translate-y-px hover:shadow-md"
              >
                Download
              </a>
            </div>
          </div>
          <div className="divide-x-2 space-x-4 transition ease-in duration-150 mb-10">
            <a
              className="text-primary-500 hover:text-primary-700 "
              href={github}
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
            <a
              className="text-primary-500 hover:text-primary-700 pl-4"
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
            <a
              className="text-primary-500 hover:text-primary-700 pl-4"
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {email}
            </a>
          </div>
          {/* SKILLS */}
          <section className="mb-16">
            <SectionHeading>Skills</SectionHeading>
            <ul className="divide-y">
              {Object.entries(skills).map(([skill, values], i) => (
                <li key={i} className="text-gray-600 flex mb-3 pt-4">
                  <div className="mr-2 text-gray-500 font-bold w-64">
                    {skill}
                  </div>
                  <p className="flex-1">
                    {values.map(({ name }) => name).join(", ")}
                  </p>
                </li>
              ))}
            </ul>
          </section>

          {/* PROJECTS */}
          <section className="mb-16">
            <SectionHeading>Projects</SectionHeading>
            <ul>
              {projects
                .filter(project => project.featured)
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map(({ project, highlights }) => {
                  const {
                    id,
                    name,
                    oneLiner,
                    technologies,
                    website,
                    repository,
                  } = project

                  return (
                    <li key={id} className="mb-8 text-gray-700">
                      <Item
                        title={name}
                        subtitle={oneLiner}
                        website={website}
                        repository={repository}
                        technologies={technologies}
                      >
                        {highlights}
                      </Item>
                    </li>
                  )
                })}
            </ul>
          </section>

          {/* WORK EXPERIENCE */}
          <section className="mb-16">
            <SectionHeading>Experience</SectionHeading>
            <ul>
              {experience
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map(({ company, position, from, to, highlights }) => {
                  const { id, name } = company
                  return (
                    <li key={id} className="mb-8 text-gray-700">
                      <Item
                        title={name}
                        subtitle={position}
                        date={{ from, to }}
                      >
                        {highlights}
                      </Item>
                    </li>
                  )
                })}
            </ul>
          </section>

          {/* EDUCATION */}
          <section className="mb-16">
            <SectionHeading>Education</SectionHeading>
            <ul>
              {education
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map(({ school, program, from, to }) => {
                  const { id, name } = school
                  return (
                    <li key={id} className="mb-3 text-gray-700">
                      <Item
                        title={name}
                        subtitle={program}
                        date={{ from, to }}
                      />
                    </li>
                  )
                })}
            </ul>
          </section>
        </div>
      </section>
    </Layout>
  )
}

export default ResumePage

export const query = graphql`
  query($author: String!) {
    markdownRemark(frontmatter: { personId: { eq: $author } }) {
      frontmatter {
        name
        github
        linkedin
        email
        # download resume
        resume {
          publicURL
        }
        # skills
        skills {
          Programming: programming {
            name
          }
          Libraries: libraries {
            name
          }
          Cloud: cloud {
            name
          }
          Strengths: strengths {
            name
          }
        }

        # projects
        projects {
          project {
            id
            name
            oneLiner
            website
            repository
            technologies {
              name
            }
          }
          featured
          order
          highlights
        }

        # experience
        experience {
          company {
            id
            name
          }
          position
          from(formatString: "MMMM, YYYY")
          to(formatString: "MMMM, YYYY")
          highlights
        }

        # education
        education {
          school {
            id
            name
          }
          program
          order
          from(formatString: "MMMM, YYYY")
          to(formatString: "MMMM, YYYY")
        }
      }
    }
  }
`
